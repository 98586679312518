@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Custom-1";
  src: url("../public/font/DMSans-Medium.ttf");
}

@font-face {
  font-family: "Custom-2";
  src: url("../public/font/Inter-Medium.ttf");
}
body {
  @apply
   bg-cream
}