.workBox {
    @apply
    flex flex-col
    transition-all duration-75
    rounded-2xl
    px-6 py-8
}
.image {
    @apply
    w-16 h-16
    rounded-full
}

.title {
    @apply
    text-xl font-bold mt-3
}

.description {
    @apply
    mt-2 mb-3
}