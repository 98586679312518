/* Navbar Styles */
.navbar {
    background-color: white;
    border-bottom: 1px solid #ddd;
    padding: 12px 20px;
    font-family: Arial, sans-serif;
}

.navbarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

/* Logo */
.logo {
    font-size: 24px;
    font-style: italic;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

/* Menu Toggle Button */
.menuToggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
}

.menuToggle .icon {
    width: 24px;
    height: 24px;
}

/* Navigation Links */
.navLinks {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 0;
    padding: 0;
}

/* Navigation Items */
.navItem {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.3s;
}

.navItem:hover {
    color: #666;
}

/* Vloader Item */
.vloader {
    display: flex;
    align-items: center;
    gap: 5px;
}

/* 🔥 New Badge */
.badge {
    background-color: red;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 6px;
    border-radius: 12px;
    animation: pulse 1.5s infinite;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .menuToggle {
        display: block;
    }

    .navLinks {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        background: white;
        padding: 20px;
        border-bottom: 1px solid #ddd;
    }

    .open {
        display: flex;
    }
}

/* 🔥 Pulse Animation */
@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.6; }
    100% { opacity: 1; }
}
